import styled from "styled-components"

export const Wrapper = styled.div`
  width: ${(props) => props.widthInPercentage};
`
export const LatestEventHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`

export const TableWrapper = styled.div`
  border-bottom: 1px solid #dde1e7;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0 3px 0;
`
export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dde1e7;
  padding: 7px 0 7px 0;
`
export const TableHeaderWrapper = styled.div`
  color: #7b828a;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  width: ${(props) => props.width};
`
export const TableBodyWrapper = styled.div`
  width: 100%;
`
export const TableColWrapper = styled.div`
  padding: 0 5px 0 0;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  width: ${(props) => props.width};
`
export const LatestEventsLink = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #7b828a;
  margin: 16px 0;
  cursor: pointer;
`
export const TableLinkColWrapper = styled.div`
  padding: 0 5px 0 0;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  width: 25%;
  cursor: pointer;
`
export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalFooter"] {
    display: none;
  }
  [class^="Modalstyles__ModalContent"] {
    padding: 0px 0 0 0;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  [class^="Modalstyles__ModalHeader"] {
    padding-top: 10px;
    padding-bottom: 0;
  }
  [class^="Header__HeaderContainer"] {
    margin: 22px 0;
  }
  [class^="EventsLogstyles__EventLogsWrapper"] {
    margin: unset !important;
  }
  [class^="Tablestyles__TableRow"] {
    height: auto !important;
    @media (max-width: 900px) {
      width: 1024px !important;
    }
  }
  [class^="SkeletonLoaderstyle__SkeletonWrapper"] {
    height: 20px;
  }

  [class^="TextOverflowstyles__NameSpan"] {
    text-overflow: unset !important;
    white-space: normal !important;
    padding: 8px 0 !important;
    box-sizing: border-box !important;
  }
  [class^="Tablestyles__TableBody"] {
    height: calc(100vh - 280px) !important;
    min-height: calc(100vh - 280px) !important;
  }
  [class^="Tablestyles__TableData"] {
    .span {
      max-width: fit-content;
    }
  }

  > [class^="Tablestyles__TableHeader"] {
    [class^="Tablestyles__TableRow"] {
      [class^="Tablestyles__TableData"] {
        min-width: calc(100% / 5) !important;
      }
    }
  }
`
export const ModalFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  [class^="rbt-input-main"] {
    min-width: 320px;
  }
  [class^="inputstyles__InputContainer"] {
    .icons-left {
      left: 16px;
      svg {
        stroke: #7e8691;
      }
    }
  }

  [class^="rbt"][tabindex="-1"] {
    width: 125%;
  }
`
export const ModalTableWrapper = styled.div`
  position: relative;

  [class^="Tablestyles__TableContainer"] {
    padding: 0;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 990;
      background: white;
      overflow: visible;
      padding-right: 0;
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      min-height: 410px;
      height: 410px;
      [class^="Tablestyles__TableRow"]
        [class^="Tablestyles__TableData"][data-column-key="checkbox"]
        input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 12px;
    margin-top: 0;
    height: auto !important;
  }
  [class^="Tablestyles__TableData"] {
    min-width: calc(100% / 4) !important;
    &:nth-child(2) {
      span {
        padding-left: 0;
      }
    }
    &:last-child {
      padding-right: 0;
    }
    > .span {
      display: block;
      width: 100%;
      padding-left: 5px;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #7b828a;
      max-width: fit-content;
    }

    [class^="TextOverflowstyles"] {
      text-overflow: unset !important;
      white-space: normal !important;
      padding: 8px 0 !important;
      box-sizing: border-box !important;
    }
  }

  [class^="Tablestyles__TablePagination"] {
    border-top: 1px solid #dde1e7;
  }

  [class^="Tablestyles__TableNavigationInfo-sc-15sgrue-11"] {
    input {
      cursor: pointer;
    }
  }
  [class^="Modalstyles__ModalOverlayer-sc-165w0k7-0"] {
    background-color: white;
  }
`

export const LatestEventsTableSkeletonWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
