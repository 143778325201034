import { useEffect } from "react"
import { useLocation } from "react-router-dom"

// Doesn't work in all cases with Cloud Gateway and Cloud Integrated, need
// investigation. It's possible they use custom scrollbars, or we have some
// specific styling in ui-elevateplatform that causes this to work as expected.
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    // This element should never be missing since it is the ID of the parent,
    // but for some reason it isn't found on all pages when embedded in Mimecast
    // Cloud Integrated, so we check for existence before calling .scrollTo.
    // Should consider refactoring to use refs.
    document.getElementById("vision-dashboard")?.scrollTo(0, 0)
  }, [pathname])

  return null
}
