export const generateUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const camelToTitleCase = (str) =>
  str
    // Insert space before upper case letters
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    // Capitalize first letter
    .replace(/^./, (match) => match.toUpperCase())

export const dashToTitleCase = (str) =>
  str
    // Replace hyphen with space
    .replace(/-/g, " ")
    .replace(/_/g, " ")
    // Temporarily split string by spaces
    .split(" ")
    // Capitalize each word
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    // Join words again
    .join(" ")

export const replaceAllWhiteSpace = (str = "", value = "-") => {
  return str.replace(/\s/g, value)
}

export const humanizeUppercase = (value) => {
  return humanize(value).toUpperCase()
}

export function capitalize(value) {
  if (typeof value !== "string" || !value.length > 0) return null
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export function humanize(str) {
  var i,
    frags = str?.split(str?.includes("_") ? "_" : "-")
  for (i = 0; i < frags?.length || 0; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags?.join(" ")
}

export function humanizeListOfStrings(strings) {
  return strings.map(humanize)
}

// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
export function stringToBase64(string) {
  if (string == null) {
    return string
  }
  const bytes = new TextEncoder().encode(string)
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte),
  ).join("")
  return btoa(binString)
}

// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
export function base64ToString(base64) {
  if (base64 == null) {
    return base64
  }
  const binString = atob(base64)
  return new TextDecoder().decode(
    Uint8Array.from(binString, (m) => m.codePointAt(0)),
  )
}

export function isEmpty(value) {
  return value === undefined || value === null || value === ""
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
  return email.match(re)
}
