import React from "react"
import {
  IndividualHumanRiskScoreInfoSection,
  AttackScoreExplanation,
} from "./components"
import { darklyGetFlag } from "@src/utils/darkly"
import StatusCheck from "@src/scenes/IndividualProfilePage/ProfileInfo/components/StatusCheck/StatusCheck"
import { DashboardCard } from "@src/components/DashboardCard"
import { SpacingY } from "@src/components/SpacingY"
import { useIndividualProfileState } from "../IndividualProfileContextProvider"
import { useRequest } from "ahooks"
import { getIndividualScoresHistoricalActionScores } from "@src/services/apis/reputation"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { useParams } from "react-router-dom"
import moment from "moment"
import { useSelector } from "react-redux"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import { viewType } from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/utils"
import { SpacingX } from "@src/components/SpacingX"
import ActionLog from "../ActionLog/ActionLog"

export default function ProfileInfo({
  onSwitchToEventsTab,
  onSwitchToActionlogsTab,
}) {
  const vcDemo = darklyGetFlag("show-vc-demo-features")
  const { individualData } = useIndividualProfileState()
  const person_nid = individualData.es_person_id
  const { shortname: organization_nid } = getCompanyInfo()
  const { profileId } = useParams()
  const startDate = `${moment().subtract(3, "month").format("YYYY-MM")}-01`
  const showMDV2 = darklyGetFlag("manager-dashboard-v2")
  const userData = useSelector(getWhoAMI)
  const showAttackFactor = (showMDV2 ? viewType(userData) : "") !== "manager"

  const { data = [] } = useRequest(
    () =>
      getIndividualScoresHistoricalActionScores({
        organization_nid: organization_nid,
        profileId,
        start_date: startDate,
        person_nid,
      }),
    {
      refreshDeps: [],
      defaultPageSize: 25,
      formatResult: (response) => response.data,
    },
  )

  const buildLatestScoreData = (key) => {
    const latestData = data[key].findLast(({ score }) => score !== null)
    return {
      key: key,
      current: latestData?.score || 0,
      prev: latestData?.previous_score || 0,
    }
  }

  const attackData = Object.keys(data)
    .filter((key) => {
      return key.startsWith("attack_")
    })
    .map(buildLatestScoreData)

  const actionData = Object.keys(data)
    .filter((key) => {
      return !key.startsWith("attack_") && key !== "attack" && key !== "overall"
    })
    .map(buildLatestScoreData)

  return (
    <SpacingY size="md">
      <IndividualHumanRiskScoreInfoSection
        actionData={actionData}
        onSwitchToEventsTab={onSwitchToEventsTab}
        onSwitchToActionlogsTab={onSwitchToActionlogsTab}
      />
      {showAttackFactor && (
        <SpacingX size="md">
          <DashboardCard
            flex="3"
            title="Attack Factor"
            subtitle="Measures how frequently you are attacked from outside sources"
            spacing="xs"
            info={
              <div>
                Measures how frequently you are targeted compared to others in
                your organization.
                <br />
                <br />
                These factors are not something you can control, and your score
                is directly influenced by time. Tenure also comes into play, and
                a longer tenured employee&apos;s score is lower.
                <br />
                <br />A low score is good. Attack Factor includes Malware and
                Actual Phishing.
              </div>
            }
          >
            <AttackScoreExplanation
              onSwitchToEventsTab={onSwitchToEventsTab}
              attackData={attackData}
            />
          </DashboardCard>
          <DashboardCard flex="2" title="Action Log">
            <ActionLog
              profileId={person_nid}
              onSwitchToActionlogsTab={onSwitchToActionlogsTab}
            />
          </DashboardCard>
        </SpacingX>
      )}
      {vcDemo && <StatusCheck />}
    </SpacingY>
  )
}
