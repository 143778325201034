import React, { memo } from "react"

import { renderLegendForPrint } from "./graph"
import {
  GroupChart,
  AxisLabel,
  TitleLegend,
  Column,
  Row,
  Title,
  Note,
} from "./styles"
import { ActionBoxes } from "@src/scenes/Dashboard/components/ThirdQuestion/components/ActionBoxes"
import { useSelector } from "react-redux"
import {
  selectTrendSummaryCampaigns,
  selectTrendSummaryOverallScore,
} from "@src/services/redux/trendSummary/selectors"

function HistoricalTrendForPrint({
  printChartRef,
  behaviorScores,
  behaviorsGraphInfo,
  disabledLines,
  setDisabledLines,
}) {
  if (!behaviorScores || !Object.keys(behaviorScores).length) return null

  const { data } = useSelector(selectTrendSummaryCampaigns)
  const { overallTrend } = useSelector(selectTrendSummaryOverallScore)
  const totalCount = data && data?.count ? data.count : 0

  return (
    <>
      <div style={{ padding: 40 }}>
        <Row
          justifyContent="space-between"
          alignItems="center"
          padding="0 8px 26px 0px"
        >
          <Title>Performance Over Time - Last 12 Months</Title>
        </Row>
        <div
          style={{
            justifyContent: "space-between",
            lignItems: "center",
            width: "100%",
          }}
        >
          <ActionBoxes totalCount={totalCount} overallTrend={overallTrend} />
        </div>
        <Row>
          <Column>
            <AxisLabel
              transform="rotate(180deg)"
              writingMode="vertical-rl"
              textOrientation="mixed"
            >
              Score Distribution
            </AxisLabel>
          </Column>
          <Column width="100%">
            <GroupChart ref={printChartRef} />
            <AxisLabel margin="0  0 0 auto" padding="18px 0 0 0">
              Time
            </AxisLabel>
          </Column>
          <Column minWidth="187px">
            <TitleLegend padding="0 0 18px 0">
              Show trend lines for:
            </TitleLegend>
            {renderLegendForPrint(
              behaviorsGraphInfo,
              disabledLines,
              setDisabledLines,
            )}
          </Column>
        </Row>
        <Row>
          <Note>Note: Data shown includes all departments.</Note>
        </Row>
      </div>
    </>
  )
}

export default memo(HistoricalTrendForPrint)
