import { useEffect } from "react"
import { useHistory, useLocation, matchPath } from "react-router-dom"
import mimecastCgShellClient from "@src/MimecastCgShellClient"
import { forceNavigate } from "@src/utils/forceNavigate"
import { useObserveRouteChanges } from "@src/utils/useObserveRouteChanges"
import { AwsRum } from "aws-rum-web"

export function RouteWatcher({
  pollForChanges,
  awsRum = null,
}: {
  pollForChanges: boolean
  awsRum?: AwsRum | null
}) {
  const history = useHistory()
  const location = useLocation()

  // Publish a custom event so shell apps know when vision triggers a route change
  useEffect(() => {
    mimecastCgShellClient.notifyRouteChange(location.pathname)
  }, [location])

  useEffect(() => {
    // For now these are the only variable routes we care about, but need to
    // come up with a more generic solution to avoid having to hard code them
    // here. No way in react-router v5 to get the router's current match unless
    // we're in the subtree of the matching route, but v6 has useMatches which
    // looks like what we need here:
    // https://reactrouter.com/en/main/hooks/use-matches
    const match = matchPath(location.pathname, {
      path: [
        "/vision2/:profileId/profile",
        "/engagement/vision2/:profileId/profile",
        "/engagement/vision2/risk-response-engine/watchlists/:watchlistId/individuals",
      ],
      exact: true,
    })

    awsRum?.recordPageView(
      match?.path
        ? { pageId: match.path, pageAttributes: match.params }
        : location.pathname,
    )
  }, [awsRum, location])

  // This ensures that any route changes from anywhere in the app get detected
  // by ui-vision, even outside vision's instance of react-router.
  const lastRoute = useObserveRouteChanges({ when: pollForChanges })

  useEffect(() => {
    if (pollForChanges && lastRoute) {
      forceNavigate(history, lastRoute)
    }
  }, [history, lastRoute, pollForChanges])

  // Mad hacks to make sure CG "sends" router events to Vision, since right now
  // CG is not aware of routing events inside Vision. This avoids CG getting
  // "stuck" on menu items that don't navigate anymore because CG thinks the
  // route hasn't changed.
  useEffect(() => {
    const externalRouteChangeListener = (url: string | undefined) => {
      if (url) {
        forceNavigate(history, url)
      }
    }
    mimecastCgShellClient.on("menuItemClick", externalRouteChangeListener)
    return () => {
      mimecastCgShellClient.off("menuItemClick", externalRouteChangeListener)
    }
  }, [history])

  return null
}
