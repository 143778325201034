import elevate from "./elevate.json"
import mimecast from "./mimecast.json"

export type GlobalConfig = {
  FEATURE_FLAG_PROVIDER: string
  DASHBOARD_TITLE: string
  BEHAVIOR_DESCRIPTOR: string
  BEHAVIOR_BREAKDOWN_TITLE: string
  ENABLE_RECOMMENDATIONS_TO_REDUCE_RISK: boolean
  ENABLE_DASHBOARD_INSIGHTS: boolean
  ENABLE_BEHAVIOR_TRENDS_OVER_TIME: boolean
  ENABLE_ATTACK_FACTOR_DISTRIBUTION: boolean
  ENABLE_RAP_TABLE_MODE: boolean
  ENABLE_R3_CREATE: boolean
  ENABLE_RAP_ADVANCED_FILTERS: boolean
  ENABLE_RAP_BULK_ACTIONS: boolean
  ENABLE_IRP_ALL_TRENDS_GRAPH: boolean
  ENABLE_IRP_ATTACK_EXPLANATION_CARDS: boolean
  ENABLE_ADDITIONAL_HUMAN_RESOURCE_FIELDS: boolean
  ACTIVE_ACTIONS_OVERRIDE: string[] | null
  ENABLE_RAP_DATA_EXPORT: boolean
  ENABLE_ACTION_LOGS_EXPORT: boolean
  ENABLE_EVENT_LOGS_EXPORT: boolean
  ENABLE_ORG_ATTACK_FACTOR: boolean
  RAP_FIELD_EXCLUSIONS: string[]
  RAP_SORT_EXCLUSIONS: string[]
  RAP_FILTER_EXCLUSIONS: string[]
  ACTION_LOG_CONTEXT: string
  ENABLE_IRP_ACTION_LOG_GROUPING: boolean
  ELLIE: boolean
  SHOW_LAST_SCORING_DATETIME: boolean
  PROVISIONING_SCREEN_MESSAGE: string
}

const configs: { [key: string]: GlobalConfig } = {
  elevate,
  // CI is identical to CG for now. If they diverge, make separate configs.
  mimecast_cg: mimecast,
  mimecast_ci: mimecast,
} as const

declare const GLOBAL_CONFIG: keyof typeof configs

export function getGlobalConfig<K extends keyof GlobalConfig>(
  key: K,
): GlobalConfig[K] {
  const config = configs[GLOBAL_CONFIG]
  if (!Object.prototype.hasOwnProperty.call(config, key)) {
    throw new Error(`Config property missing ${key}`)
  }
  return config[key]
}
