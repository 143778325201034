declare global {
  interface Window {
    navigation: any
  }
}

import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { extractRoute } from "./extractRoute"

export function useObserveRouteChanges({
  when = false,
}: { when?: boolean } = {}) {
  const [lastRoute, setLastRoute] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    if (!when) {
      return
    }

    // Navigation API is not available in all browsers, so we check for existence before using
    if (window.navigation) {
      const pathChangeHandler = (event: any) => {
        const url = new URL(event.destination.url)
        setLastRoute(extractRoute(url))
      }
      window.navigation.addEventListener("navigate", pathChangeHandler)
      return () => {
        window.navigation.removeEventListener("navigate", pathChangeHandler)
      }
    } else {
      // This is perverse and I hate it, but it works well for now.
      const interval = setInterval(() => {
        setLastRoute(extractRoute(location))
      }, 500)
      return () => {
        clearInterval(interval)
      }
    }
  }, [location, when])

  return lastRoute
}
