import { useMemo } from "react"
import { zip } from "@src/utils/zip"
import { useRequest } from "ahooks"
import { fetchActionPerformance as _fetchActionPerformance } from "../fetchActionPerformance"

type PerformanceResponseItem = { date: string; events: number }

type ActionPerformanceResponse = {
  data?: readonly {
    type: string
    data: readonly PerformanceResponseItem[]
  }[]
}

type ArgumentType = {
  activeActions: string[]
  months?: number
  es_person_id?: string
  fetchActionPerformance?: (
    action: string,
    opts?: {
      months?: number
      es_person_id?: string
    },
  ) => Promise<ActionPerformanceResponse>
}

type ReturnType = {
  loading: boolean
  attackCounts: { date: string; value: number }[]
  totalsByBehavior: {
    malware: number
    actual_phishing: number
  }
}

// Monthly attacks counts are the sum of all malware events, all actual phishing
// blocked events, and all actual phishing received events
export function useMonthlyAttackCounts({
  activeActions,
  months,
  es_person_id,
  fetchActionPerformance = _fetchActionPerformance,
}: ArgumentType): ReturnType {
  const { loading, data } = useRequest<
    [ActionPerformanceResponse, ActionPerformanceResponse]
  >(
    () =>
      Promise.all(
        ["malware", "real_world_phishing"].map((action) =>
          activeActions.map((key) => key.replaceAll("-", "_")).includes(action)
            ? fetchActionPerformance(action, { months, es_person_id })
            : Promise.resolve({ data: [] }),
        ),
      ),
    {
      refreshDeps: [months, es_person_id, activeActions.slice().sort().join()],
    },
  )

  const [malwareResponse, rwpResponse] = data || []

  const malwareData = useMemo(
    () => malwareResponse?.data || [],
    [malwareResponse],
  )

  const rwpData = useMemo(
    () =>
      (rwpResponse?.data || []).filter(({ type }) =>
        ["actual_phishing_received", "actual_phishing_blocked"].includes(type),
      ),
    [rwpResponse],
  )

  const attackCounts = useMemo(() => {
    return zip(
      [...malwareData, ...rwpData]
        // default sort order is latest to earliest, we want earliest to
        // latest so we reverse all data
        .map((category) => category.data?.slice().reverse() ?? [])
        .filter((counts) => Boolean(counts.length)),
    ).map((monthCounts: PerformanceResponseItem[]) => {
      return {
        date: monthCounts[0].date,
        value: sumEvents(monthCounts),
      }
    })
  }, [malwareData, rwpData])

  return {
    loading,
    attackCounts,
    totalsByBehavior: {
      malware: sumEvents(malwareData.flatMap((malware) => malware.data)),
      actual_phishing: sumEvents(rwpData.flatMap((rwp) => rwp.data)),
    },
  }
}

function sumEvents(counts: { events: number }[]): number {
  return counts.reduce((acc, { events }) => acc + events, 0)
}
