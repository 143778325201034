/* eslint-disable indent */
import { Map, fromJS, List } from "immutable"

import {
  WHO_AM_I,
  RESET_LOADED,
  RESET_TAB,
  INCREMENT_LOADED,
  SET_LOADED,
  GET_PERFORMER_DATA,
  GET_STRAGGLER_DATA,
  GET_HIERARCHY_DATA,
  SET_TAB,
  GET_BEHAVIOR_DATA,
  GET_SCORES_DIST,
  GET_TOP_DEPARTMENTS,
  GET_RISK_DIST,
  SORT_COLUMN,
  GET_BEHAVIOR_MAP,
  GET_SUB_BEHAVIOR_MAP,
  GET_BREADCRUMBS,
  SET_PAGE,
  SET_PAGINATION,
} from "../actions"

export const initialState = new Map({
  loadedCount: 0,
  tab: 0,
  data: new Map({
    me: new Map(),
    company: new Map({
      topPerformers: new Map(),
      stragglers: new Map(),
      riskDistributions: new List(),
      hierarchy: new Map(),
      topDepartments: new List(),
      scoresDistributions: new Map(),
    }),
    department: new Map({
      topPerformers: new Map(),
      stragglers: new Map(),
      riskDistributions: new Map(),
      hierarchy: new Map(),
      scoresDistributions: new Map(),
      subBehaviorMap: new Map(),
    }),
    team: new Map({
      riskDistributions: new Map(),
      hierarchy: new Map(),
      scoresDistributions: new Map(),
      subBehaviorMap: new Map(),
    }),
    behaviors: new List(),
    breadcrumbs: new List(),
  }),
  page: new Map({
    level: "company",
    levelId: null,
  }),
  sorting: new Map({
    columnName: "name",
    order: "asc",
  }),
  pageNumber: 0,
})

const getPath = (action, keys) => ["data", ...keys.map((key) => action[key])]
const visionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case WHO_AM_I: {
      return state.set("me", action.whoAmI)
    }
    case RESET_LOADED: {
      return state.set("loadedCount", 0)
    }
    case INCREMENT_LOADED: {
      return state.set("loadedCount", state.get("loadedCount") + 1)
    }
    case SET_LOADED: {
      return state.set("loadedCount", 7)
    }
    case SET_TAB: {
      return state.set("tab", action.tab)
    }
    case RESET_TAB: {
      return state.set("tab", 0).set("pageNumber", 0)
    }
    case SORT_COLUMN: {
      const path = getPath(action, ["level", "label"])
      if (action.levelId) path.splice(3, 0, action.levelId)
      return state
        .set(
          "sorting",
          new Map({
            columnName: action.columnName,
            order: action.order,
          }),
        )
        .setIn(path, fromJS(action.data))
    }
    case SET_PAGINATION: {
      return state.set("pageNumber", action.pageNumber)
    }
    case SET_PAGE: {
      return state.set(
        "page",
        new Map({
          level: action.level,
          levelId: action.levelId,
        }),
      )
    }

    case GET_PERFORMER_DATA:
    case GET_STRAGGLER_DATA: {
      const path = getPath(action, ["level", "label", "category"])
      if (action.levelId) path.splice(3, 0, action.levelId)
      return state
        .setIn(path, fromJS(action.data))
        .set("loadedCount", state.get("loadedCount") + 1)
    }

    case GET_BEHAVIOR_MAP: {
      const path = getPath(action, ["level", "label"])
      return state
        .setIn(path, fromJS(action.data))
        .set("loadedCount", state.get("loadedCount") + 1)
    }

    case GET_SUB_BEHAVIOR_MAP: {
      const path = getPath(action, ["level", "label"])
      if (action.levelId) path.splice(3, 0, action.levelId)
      return state
        .setIn(path, fromJS(action.data))
        .set("loadedCount", state.get("loadedCount") + 1)
    }
    case GET_RISK_DIST:
    case GET_SCORES_DIST:
    case GET_TOP_DEPARTMENTS:
    case GET_HIERARCHY_DATA: {
      const path = getPath(action, ["level", "label"])
      if (action.levelId) path.push(action.levelId)
      return state
        .setIn(path, fromJS(action.data))
        .set("loadedCount", state.get("loadedCount") + 1)
    }

    case GET_BEHAVIOR_DATA: {
      const path = getPath(action, ["label"])
      return state.setIn(path, fromJS(action.data))
    }

    case GET_BREADCRUMBS: {
      const path = getPath(action, ["label"])
      return state
        .setIn(path, fromJS(action.data))
        .set("loadedCount", state.get("loadedCount") + 1)
    }
    default:
      return state
  }
}

export default visionReducer
